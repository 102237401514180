import(/* webpackMode: "eager" */ "/var/www/poiscaille/apps/shop/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/www/poiscaille/apps/shop/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/form/block/NewsletterForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/layout/banner/BannerContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/layout/footer/FooterLinkCookiesPreferences.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","name"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/layout/navbar/NavbarInner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/newsletter-modal/NewsletterModalInner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/styles/images/logo-poiscaille-blanc.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/styles/images/logo-poiscaille.svg");
